<template>
	<!-- 会员中心 -->
	<div class="bidding">
		<div class="project">
			<div class="query-area">
				<h3>网络竞价大厅</h3>
				<el-form class="" :model="form" label-position="right" label-width="80px" inline ref="form">
					<el-form-item label="项目名称">
						<el-input style="width: 190px" v-model="form.xmmc" placeholder="项目名称"> </el-input>
					</el-form-item>
					<el-form-item label="产权类别">
						<el-select style="width: 190px" v-model="form.cqlx">
							<el-option
								v-for="item in typeOptions"
								:key="item.label"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="行政区">
						<el-cascader
							style="width: 190px"
							v-model="form.xzqbh"
							:props="props"
							:options="regionOptions"
							:show-all-levels="false"
						></el-cascader>
					</el-form-item>
					<div class="button" style="display: inline-block; margin: 0">
						<el-form-item>
							<el-button class="query-btn" type="primary" @click="query">查询</el-button>
							<el-button class="query-btn" type="primary" @click="clear">清空</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div class="table-card">
				<div class="table-con">
					<el-table
						border
						class="table"
						:data="tableData"
						style="width: 100%"
						:row-class-name="handleRowClass"
					>
						<el-table-column
							align="center"
							prop="xmbh"
							label="项目编号"
							width="240px"
						></el-table-column>
						<el-table-column
							align="center"
							prop="xmmc"
							label="项目名称"
							width="180px"
						></el-table-column>
						<el-table-column align="center" prop="gpjg" label="底价(元)"></el-table-column>
						<el-table-column align="center" prop="myBj" label="我的报价(元)"></el-table-column>
						<el-table-column align="center" prop="currentBj" label="竞价最高报价(元)"></el-table-column>
						<el-table-column
							align="center"
							prop="jjjzsj"
							label="竞价截止时间"
							width="160px"
						></el-table-column>
						<el-table-column align="center" label="操作" width="130" fixed="right">
							<template slot-scope="scope">
                <!-- 查看按钮 -->
								<el-button
									style="width: 46px; height: 28px; padding: 0 8px"
									type="primary"
									@click="showDetail(scope.row)"
									>查看</el-button
								>
                <!-- 竞价按钮 -->
								<el-button
									style="width: 46px; height: 28px; padding: 0 8px"
									type="success"
									size="small"
									@click="bidding(scope.row)"
									>竞价</el-button
								>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="pagenation">
					<el-pagination
						background
						layout="total, prev, pager, next"
						:current-page="currentPage"
						:page-size="pageSize"
						:total="total"
						@current-change="currentChange"
					>
					</el-pagination>
				</div>
			</div>
		</div>

		<bidding-dialog
			v-if="dialogVisible"
			:dialogVisible="dialogVisible"
			:data="current"
			@close="dialogClose"
			@submit="submit"
		></bidding-dialog>
	</div>
</template>

<script>

import {getMyBiddingHall } from '@/api/projectManage'
import { getAreaTree } from '@/api/certificate'
import { mapGetters } from 'vuex'
import biddingDialog from '../components/biddingDialog'
import { getXmxxDetail, saveBidding, getButtonStatus } from '@/api/exchange'
import {  removeEmptyChildren } from "@/js/utils/util";
export default {
	components: {
		biddingDialog,
	},
	data() {
		return {
			form: {
        xmbh:'',
				xmmc: '',
				cqlx: '',
				xzqbh: '',
			},
			typeOptions: [],
			regionOptions: [],
			tableData: [],
			props: {
				label: 'areaName',
				children: 'children',
				value: 'id',
				fullPathName: 'fullPathName',
				checkStrictly: true,
				emitPath: false,
			},
			statusOptions: [{ label: '全部', value: '' }],
			changeTypeOptions: [{ label: '全部', value: '' }],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			dialogVisible: false,
			current: {},
			biddingInterval: null,
		}
	},
	created() {
		this.getTypes()
		this.getRegions()
		this.initData()

	},
	computed: mapGetters(['userInfo', 'identityObj']),
	destroyed() {
		if (this.biddingInterval) {
			clearInterval(this.biddingInterval)
		}
	},
	methods: {

		async initData() {
			// 获取数据
			// if (this.biddingInterval) {
			// 	clearInterval(this.biddingInterval)
			// }
			let that = this
			that.tableData = []
			let params = Object.assign(that.form, {
				current: that.currentPage,
				size: that.pageSize,
			})
			let res = await getMyBiddingHall(params)
			let { data, status, message } = res
			if (status == 200) {
				that.tableData = data.records
        console.log(that.tableData,"这里为全部的信息" )
				that.total = parseInt(data.total)
			}
		},

    // 条件查询
		query() {
			this.currentPage = 1
			this.initData()
		},
    // 清空查询条件
		clear() {
			this.currentPage = 1
			this.form = {
				region: '',
				type: '',
				area: '',
				code: '',
				name: '',
				maxArea: '',
			}
			this.initData()
		},
		async getRegions() {
			// 获取行政区树
			let param = {
				level: '3',
				parentCode: null,
			}
			let res = await getAreaTree(param)
			let { code, data, msg } = res
			if (code == 0) {
				this.regionOptions = removeEmptyChildren(data);
			} else {
				this.$message.error(msg)
			}
		},
    // 获取产权类型
		getTypes() {
			let cqList = JSON.parse(localStorage.getItem('types'))
			this.typeOptions = cqList.map(x => {
				return {
					label: x.lx,
					value: x.bh,
				}
			})
		},
    // 切换页码
		currentChange(val) {
			this.currentPage = val
			this.initData()
		},
		handelSelectChange(val) {
			// 选择发生变化
			this.initData()
		},
		handleAreaChange(val) {
			// 面积发生变化
			let obj = this.areaOptions.filter(item => item.value === val)[0]
			this.form.maxArea = obj.value2
			this.initData()
		},
		handleBlur(val) {
			// 输入框失去焦点时发生变化
			this.initData()
		},
		handleRowClass({ row, rowIndex }) {
			// 行樣式
			return 'odd'
		},
		showDetail(row) {
			// 查看项目详情
			this.$router.push({ name: 'projectDetail', query: { c: row.xmbh } })
		},
    // 点击竞价
		async bidding(row) {
      console.log(row,'这些事row的数据')
			let params = {
				xmbh: row.xmbh,
				authId: this.identityObj.identityId ? this.identityObj.identityId : '',
				currentIdentity: this.identityObj.identityTypeCode ? this.identityObj.identityTypeCode : '',
				userId: this.identityObj.userId ? this.identityObj.userId : '',
			}
			let buttonRes = await getButtonStatus(params)
			if (buttonRes.status == 200 && buttonRes.data.type == '8') {
				this.$message.error(buttonRes.data.detail)
        return
			}
			// 竞价
			let res = await getXmxxDetail({ xmbh: row.xmbh })
			let { status, data } = res
			if (status == 200) {
				this.current = data
			}
			this.dialogVisible = true
		},
		async submit(val, offer) {
			// 竞价信息提交 offer当前报价
			let total = 0
			if (offer == 0 || !offer) {
				total = parseFloat(val) + parseFloat(this.current.gpjg)
			} else {
				total = parseFloat(val) + parseFloat(this.current.dqbj)
			}
			let params = {
				xmbh: this.current.xmbh,
				amount: total,
			}
			let res = await saveBidding(params)
			let { status, data, message } = res
			if (status == 200) {
				this.$message.success(message)
				this.dialogVisible = false
				this.initData()
			} else {
				this.$message.error(message)
			}
		},
    // 关闭弹窗
		dialogClose(val) {
			this.dialogVisible = val
			this.initData()
		},
	},
}
</script>

<style lang="less" scoped>
.query-area {
	padding: 0 30px;
}
.bidding {
	text-align: left;
	height: 100%;
	position: relative;
	// margin-top: 46px;

	.s-title {
		.title {
			margin-left: 10px;
			font-size: 15px;
			font-weight: 600;
		}
	}
	.project {
		// margin-top: 38px;
		.table-con {
			margin-top: 12px;
			padding: 0 15px 0 18px;
		}
	}
	.news {
		margin-top: 33px;
	}
	.pagenation {
		// margin-top: 0;
		// position: absolute;
		// right: 20px;
		// bottom: 33px;
		text-align: right;
	}

	.form {
		.el-form-item {
			margin-bottom: 5px;
		}
	}
}
</style>

<style lang="less">
.bidding {
	.el-dialog__wrapper {
		position: absolute;
	}
	.v-modal {
		position: absolute;
	}
	.el-checkbox-button__inner {
		width: 160px;
		border-left: 1px solid #dcdfe6;
	}
	.el-dialog--center .el-dialog__body {
		padding: 25px 60px 17px 38px;
	}
	.el-checkbox-button.is-checked .el-checkbox-button__inner {
		border-radius: 5px;
		border: solid 1px #00a0e9;
		background: #fff;
		color: #00a0e9;
	}
	.el-dialog__footer {
		padding: 10px 20px 45px;
	}
}
</style>
